import React from 'react'
import SEO from '~/components/seo'
import {navigateTo} from 'gatsby-link'
import styled from '@emotion/styled'
import {breakpoints, SectionContainer} from '~/utils/styles'

export const Wrapper = styled.div`
	margin: 0 auto;
`
export const FormContainer = styled.div`
	max-width: 992px;
	min-height: 500px;
	margin: 40px auto;
	padding: 40px;
	border: 2px #ccc solid;
	@media (max-width: ${breakpoints.m}px){
		border: none;
	}
`
export const BotTrap = styled.div`
	display: none;
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactPage extends React.Component {
	constructor(props){
		super(props);
		this.state = {};
	}
	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	  };

	  handleSubmit = e => {
		e.preventDefault();
		const form = e.target;
		fetch("/contact", {
		  method: "POST",
		  headers: { "Content-Type": "application/x-www-form-urlencoded" },
		  body: encode({
			"form-name": form.getAttribute("name"),
			...this.state
		  })
		})
		  .then(() => navigateTo(form.getAttribute("action")))
		  .catch(error => alert(error));
	  };
	  render(){
		return (
		  <>
		  <SEO title="Contact Us" />
			<Wrapper>
				<SectionContainer>
					<FormContainer>
						<form name="contact" method="POST" action="/thanks" netlify-honeypot="bot-field" data-netlify="true" onSubmit={this.handleSubmit}>
							<BotTrap>
								<label>Don’t fill this out if you're human: <input name="bot-field" onChange={this.handleChange} /></label>
							</BotTrap>
							<input type="hidden" name="form-name" value="contact" />
							<label>Full Name: </label>
							<div className="form-group">
								<input type="fullname" name="fullname" className="form-control" placeholder="John Doe" onChange={this.handleChange} />
							</div>
						  <label>Email Address: </label>
						  <div className="form-group">
							<input type="email" name="email" className="form-control" placeholder="name@example.com" onChange={this.handleChange} />
						  </div>
						  <label>Message: </label>
						  <div className="form-group">
							<textarea name="message" className="form-control" onChange={this.handleChange} />
						  </div>		  
							<button type="submit" className="btn btn-primary">Submit</button>
						</form>
					</FormContainer>
				</SectionContainer>
			</Wrapper>
		  </>
		)
	  }
	
}



